import React, { useState, useEffect, useRef, } from "react";
import axios from "axios";
import styled from "styled-components";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import { CgClose } from "react-icons/cg";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";
import Swal from "sweetalert2";

const Geral = styled.div`
  width: 900px;
  border: 1px solid #ccc;
  padding: 20px;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  
`;
const Table = styled.table`
  width: 880px;
  background-color: #fff;
  padding: 20px 20px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 0.5px;
  margin: 15px auto;

`;

export const TdButton = styled.td`
  font-size: 14px;
  font-weight: bold;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  cursor: pointer;
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  padding-bottom: 25px; 
`;

export const Tr = styled.tr`
  text-align: center;
  align-items: center;

`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;


`;

export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 

`;

export const Td1 = styled.td`
  padding-top: 15px;
  text-align: center;


`;

export const Td2 = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 
`;

export const Td3 = styled.td`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  span{
    margin: 0px 5px;
  }

`;



const Div = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  margin-top: 5px;
  text-align: start;

  span{
    padding: 0px  10px;
  }
`;


const DivInterna = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  gap: 300px;

`;

const DivInterna2 = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;

`;


const Input = styled.input`
  width: 100px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;

  
`;
const Input2 = styled.input`
  width: 400px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;

  
`;

const Button = styled.button`
  cursor: pointer;
  border: none;
  background-color: #fff;
  font-weight: bold;
  font-size: 15px;
  margin: 8px;
  transition: transform 0.1s;
  align-items: center;
  margin-left: 5px;
  &:hover {
    transform: scale(1.1);
  }
  
`;

const TextArea = styled.textarea`
  width: 805px; 
  height: 50px; 
  border: 1px solid #ccc;
  border-radius: 1px;
  margin-top: 10px;
  margin-left: 10px;
  padding: 10px 10px 00px 20px;
  resize: none; /* Desabilitar o redimensionamento manual */
  box-sizing: border-box; /* Incluir o padding e a borda na largura total */
  overflow: auto; /* Permitir a rolagem automática */
  white-space: pre-wrap; /* Manter quebras de linha e espaços */
`;
const Label = styled.label`
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  margin: 0px 5px;
  padding: 0px 0px 0px 10px; 


`;

const InputArea = styled.div`
  width: auto;
  height: auto; 
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px 10px;


  
`;

const Grid = ({ onEdit, urlApi }) => {
  const ref = useRef();
  useEffect(() => {
    if (onEdit) {


      const user = ref.current;
    };
  }, [onEdit]);


  //Token da api
  const token = localStorage.getItem('token');

  const [servicoFiltreded, setServicoFiltreded] = useState([]);

  // Função para buscar eventos
  const getMateriais = async () => {
    try {
      const res = await axios.get(`${urlApi}/programacao-entressafra/materiais`, {
        headers: { Authorization: `Bearer ${token}` }
      });


      const sortedEventos = res.data.sort((a, b) => {
        return b[1] - a[1]; // Ordenando pelo segundo valor (decrescente)
      });




      setServicoFiltreded(sortedEventos); // Certifique-se de que res.data seja um array
    } catch (err) {
      console.log("Conexão falhou", err);
    }
  };


  useEffect(() => {
    getMateriais();
  }, []);



  const [mostrarInput, setMostrarInput] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [inputQuantidade, setInputQuantidade] = useState('');
  const [inputObservacao, setInputObservacao] = useState('');
  const [inputValor, setInputValor] = useState(0);

  const [showValueInput, setShowValueInput] = useState(false);

  // Função para alternar o campo de input ao clicar no botão
  const handleAdicionarMaterial = () => {
    setMostrarInput(!mostrarInput);
    setInputQuantidade('');
    setInputValue('');
    setMateriais('');
    setInputValor('');
    setInputObservacao('')
  };


  const [materiais, setMateriais] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async () => {

    const validCodes = ["4094", "179948", "191844", "178974", "4088", "4089", "179105", "195148", "4093"];
    if (validCodes.includes(inputValue)) {
      setShowValueInput(true); // Exibe a Div se o valor for válido
    } else {
      setShowValueInput(false); // Oculta a Div se o valor não for válido
    }


    if (!inputValue) {
      alert("Por favor, insira um código de material");
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.get(`${urlApi}/programacao-entressafra/materiais`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { codigoMaterial: inputValue } // Passa o código do material como parâmetro
      });

      // Verifica se o array de materiais foi retornado e acessa a primeira descrição
      if (response.data && response.data.length > 0) {
        const material = response.data[0].DESC_MATERIAL; // Acessa o campo DESC_MATERIAL do primeiro item

        setMateriais(material); // Armazena os dados completos, se necessário
      } else {
        console.log("Nenhum material encontrado.");
      }
    } catch (error) {
      console.error("Erro ao buscar materiais:", error);
    } finally {
      setIsLoading(false);
    }
  };




  const handleSubmit = async (e) => {
    e.preventDefault();

    const user = ref.current;

    const id_anoValue = onEdit.ano;
    const ordemValue = onEdit.ordem;
    const idValue = onEdit.id;
    const cod_materialValue = inputValue;
    const quantidadeValue = parseFloat(inputQuantidade);
    const observacaoValue = inputObservacao;
    const valorValue = inputValor !== "" ? parseFloat(inputValor) : 0;


    if (!cod_materialValue || !quantidadeValue) {
      return toast.warn("Preencha todos os campos!");
    }


    try {

      await axios
        .post(`${urlApi}/programacao-entressafra/programacao-materiais-post`, {
          ANO_PROGRAMACAO_MANUTENCAO: id_anoValue,
          NUMERO_PROGRAMACAO_MANUTENCAO: ordemValue,
          NUMERO_ITEM_TAREFA: idValue,
          COD_MATERIAL: cod_materialValue,
          QTDE_PROGRAMACAO: quantidadeValue,
          DESCRICAO: observacaoValue,
          VALOR_PROGRAMACAO: valorValue,
        }, { headers: { Authorization: `Bearer ${token}` } })
        .then(async ({ data }) => {
          toast.success(data);
          // Limpa os campos após salvar com sucesso
          setInputValue('');
          setInputQuantidade('');
          setInputObservacao('');
          setInputValor('');
          getProgramacaoMaterial();
        })
        .catch(async (error) => {
          if (error.response) {
            console.log("Full error response:", error.response); // Adiciona esse log para inspecionar a resposta completa
            const errorMessage = error.response.data.error || 'Erro desconhecido';
            console.log("Error:", errorMessage);
            toast.error("Erro ao adicionar: " + errorMessage);
          } else {
            console.error('Erro inesperado:', error);
            toast.error('Erro inesperado.');
          }
        });


    } catch (error) {
      console.error('Erro inesperado:', error);
      toast.error('Erro inesperado.');

    }

  };



  const [materiaisFiltrados, setMateriaisFiltreded] = useState([]);

  const getProgramacaoMaterial = async () => {
    try {
      const res = await axios.get(`${urlApi}/programacao-entressafra/programacao-materiais`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      const eventosFiltrados = res.data.filter(item => item.ANO_PROGRAMACAO_MANUTENCAO === parseInt(onEdit.ano));
      const eventosFiltrados2 = eventosFiltrados.filter(item => item.NUMERO_PROGRAMACAO_MANUTENCAO === parseInt(onEdit.ordem));
      const eventosFiltrados3 = eventosFiltrados2.filter(item => item.NUMERO_ITEM_TAREFA === parseInt(onEdit.id));

      const sortedEventos = eventosFiltrados3.sort((a, b) => {
        // Converter DATA_PROGRAMACAO para objeto Date no formato DD-MM-YYYY
        const dataA = new Date(a.DATA_PROGRAMACAO.split('-').reverse().join('-'));
        const dataB = new Date(b.DATA_PROGRAMACAO.split('-').reverse().join('-'));

        // Comparar por DATA_PROGRAMACAO primeiro
        if (dataA > dataB) return 1;
        if (dataA < dataB) return -1;

        // Se as datas forem iguais, comparar por COD_MATERIAL
        return a.COD_MATERIAL - b.COD_MATERIAL;
      });
      setMateriaisFiltreded(sortedEventos); // Certifique-se de que res.data seja um array
    } catch (err) {
      console.log("Conexão falhou", err);
    }
  };


  useEffect(() => {
    getMateriais();
    getProgramacaoMaterial();
  }, []);






  const handleExcluirMaterial = async (item) => {
    console.log(item)

    const userConfirmed = await showPopUp();
    if (!userConfirmed) {
      return;
    }

    try {
      // Tenta realizar a requisição para deletar o material utilizando o ROWID na URL
      const { data } = await axios.delete(`${urlApi}/programacao-entressafra/programacao-materiais-delete/${item}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log('Material deletado:', data);
      toast.success('Material deletado com sucesso.');
      getProgramacaoMaterial();

    } catch (error) {
      // Tratamento de erros
      console.error('Erro ao deletar o material:', error);

      let errorMessage = 'Erro desconhecido ao deletar.';
      if (error.response) {
        errorMessage = error.response.data || errorMessage;
        console.log('Erro na resposta:', error.response);
      } else if (error.request) {
        console.log('Erro na requisição:', error.request);
      } else {
        console.log('Erro na configuração da requisição:', error.message);
      }

      toast.error(errorMessage);
    }
  };



  const showPopUp = () => {
    return new Promise((resolve) => {
      Swal.fire({
        title: `Essa ação é irreversível, tem certeza que gostaria de continuar?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          container: 'custom-swal-container', // Estiliza o container principal
        },
      }).then((result) => {
        resolve(result.value); // Resolve a Promise com o valor result.value
      });
    });
  };



  return (

    <Geral>
      <Div>
        <DivInterna>
          <p>Tarefa: <strong>{onEdit.descricao_tarefa}</strong></p>
          <Button onClick={handleAdicionarMaterial}>
            {mostrarInput ? <><CgClose color={"red"} /> Fechar Campo</> : <><FaPlus color={"gold"} /> Adicionar Material</>}
          </Button>
        </DivInterna>

        {mostrarInput && (
          <div>
            <DivInterna2>
              <InputArea>
                <Div>
                  <Label>Código</Label>
                  <Input
                    type="text"
                    placeholder="Digite o código"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}

                  />
                </Div>

                <Button onClick={handleSearch}><FaMagnifyingGlass color={"blue"} /></Button>
              </InputArea>

              <Div>
                <Label>Descrição</Label>
                <Input2
                  type="text"
                  placeholder="Descrição do material"
                  value={materiais}
                  disabled
                />
              </Div>

              <Div>
                <Label>Quantidade</Label>
                <Input
                  type="text"
                  placeholder="Digite quantidade"
                  value={inputQuantidade} // Adiciona o valor para ser controlado pelo state
                  onChange={(e) => setInputQuantidade(e.target.value)}
                />
              </Div>

              {showValueInput && (
                <Div>
                  <Label>Valor</Label>
                  <Input
                    type="text"
                    placeholder="Ex. 20.0"
                    value={inputValor} // Adiciona o valor para ser controlado pelo state
                    onChange={(e) => setInputValor(e.target.value)}
                  />
                </Div>
              )}



            </DivInterna2>
            <DivInterna2>
              <Div>
                <TextArea
                  type="text"
                  placeholder="Digite observação"
                  onChange={(e) => setInputObservacao(e.target.value)}
                />
              </Div>
              <Button onClick={handleSubmit}><FaCheck color={"green"} /> Salvar</Button>
            </DivInterna2>
          </div>


        )}
        <Table >

          <Thead>
            <Tr>
              <Th>Codigo:</Th>
              <Th>Quantidade:</Th>
              <Th></Th>
              <Th>Valor:</Th>
              <Th>Data criação:</Th>
              <Th></Th>
            </Tr>
          </Thead>

          <Tbody>
            {materiaisFiltrados.map((item, i) => (
              <Tr key={i} >
                <Td>{item.COD_MATERIAL}</Td>
                <Td>{item.QTDE_PROGRAMACAO}</Td>
                <Td>{item.DESCRICAO}</Td>
                {item.VALOR_PROGRAMACAO !== 0 ? (
                  <Td>{item.VALOR_PROGRAMACAO}</Td>
                ) : (
                  <Td></Td>
                )}
                <Td>{item.DATA_PROGRAMACAO}</Td>
                <Button onClick={() => handleExcluirMaterial(item.ROWID)}><MdDelete color={"gold"} size={22} /></Button>
              </Tr>
            ))}
          </Tbody>



        </Table>

      </Div>
      <style jsx global>{`
      .swal2-container.custom-swal-container {
        z-index: 9999; 
      }
    `}</style>
    </Geral>

  );
};


export default Grid;
